import { request_python, request } from '../utils/request';
import { BackendResponse } from './data';
import { RcFile } from 'antd/es/upload/interface';

interface PresignedPostResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export interface RegisterFileResponse {
  _id: string;
  filename: string;
  s3Key: string;
  size: number;
  mimeType: string;
  token: string;
  isPublic: boolean;
  isSystemGenerated: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const getFileList = async (workspaceId: string) => {
  const res = await request<RegisterFileResponse[]>(
    '/api/v1/workspace/' + workspaceId + '/files',
    {
      method: 'get',
      ...request_python,
    },
  );
  return res;
};

export const fileDelete = async (
  fileId: string,
  params: { fileSourceId?: string; workspaceId?: string },
) => {
  const queryString = new URLSearchParams(params).toString();

  const res = await request<any>(`/api/v1/files/${fileId}?${queryString}`, {
    method: 'delete',
    ...request_python,
  });

  return res;
};

export const fileDownloadLink = async (fileId: string) => {
  const res = await request<PresignedPostResponse>(
    '/api/v1/files/presigned/' + fileId,
    {
      method: 'get',
      ...request_python,
    },
  );

  return res;
};

const getPresignedPost = async (workspaceId: string, filename: string) => {
  const res = await request<PresignedPostResponse>('/api/v1/files/presigned', {
    method: 'post',
    data: {
      workspaceId: workspaceId,
      filename: filename,
    },
    ...request_python,
  });

  return res;
};

export const registerFile = async (
  workspaceId: string,
  filename: string,
  s3Key: string,
  fileSize: number,
  mimeType: string,
) => {
  const res = await request<RegisterFileResponse>('/api/v1/files/register', {
    method: 'post',
    data: {
      workspaceId: workspaceId,
      filename: filename,
      fileSize: fileSize,
      s3Key: s3Key,
      mimeType: mimeType,
    },
    ...request_python,
  });

  return res;
};
export const uploadFlow = (
  file: RcFile,
  workspaceId: string,
  onProgress: (percent: number) => void,
) => {
  return new Promise<RegisterFileResponse>((resolve, reject) => {
    const presignedProgress = 5;
    const registerProgress = 5;

    // Allocate 5% progress for presigned URL generation
    onProgress(presignedProgress);
    
    // Call async functions inside the executor, not make the executor itself async
    getPresignedPost(workspaceId, file.name)
      .then((data: PresignedPostResponse) => {
        // Create FormData and append presigned S3 fields
        const formData = new FormData();
        for (const key in data.fields) {
          formData.append(key, data.fields[key]);
        }
        formData.append('file', file);

        // Track upload progress
        const totalBytes = file.size;
        let bytesUploaded = 0;

        // Use XMLHttpRequest for progress tracking and FormData upload
        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            bytesUploaded = event.loaded;
            const percent = presignedProgress + (bytesUploaded / totalBytes) * 90;
            onProgress(percent);
          }
        };

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            // Allocate final 5% for registerFile after upload completion
            onProgress(100 - registerProgress);
            
            registerFile(
              workspaceId,
              file.name,
              data.fields.key,
              file.size,
              file.type,
            )
              .then(register => {
                onProgress(100);
                resolve(register);
              })
              .catch(error => reject(error));
          } else {
            reject(new Error('File upload failed'));
          }
        };

        xhr.onerror = () => {
          reject(new Error('File upload failed'));
        };

        // Open the request and send the FormData
        xhr.open('POST', data.url);
        xhr.send(formData);
      })
      .catch(error => reject(error));
  });
};